import styled from "styled-components";
import React from "react";
import { Types } from "gatsby-theme-advanced";
import { WrapperCss } from "./Spacing";

const WrapperSection = styled.section`
  width: 100%;

  display: grid;
  justify-items: center;
  grid-gap: 24px;
`;

const Wrapper = styled.div`
    font-size: 22px;
    font-style: italic;
    max-width: 550px;
    margin: 0.25em 0;
    padding: 0.25em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
    background:#ececec;

    &:before {
        display: block;
        content "\\201C";
        font-size: 80px;
        position: absolute;
        left: -10px;
        top: -40px;
        color: #D83850;
    }

    & > *:not(figure, div, hr) {
        ${WrapperCss}
      }

`;

type QuoteBlockProps = {
    post: Types.Post;
  };

const BlockQuoteBig = ({ post }: QuoteBlockProps): JSX.Element => {
    if (!post.description) {
        return <></>;
    }

    return <WrapperSection>
        <Wrapper>
        <div>{post.description}</div>
        </Wrapper>
    </WrapperSection>;

    // return <Wrapper>
    //     <h6>la la la</h6> {post.description}
    // </Wrapper>
};

export default BlockQuoteBig;