import styled from "styled-components";

const LayoutWidthContainer = styled.div`
  width: 100%;
  padding: 0 16px 0 16px;
  justify-self: center;

  // max-width: calc(1144px + 2 * 16px);
  max-width: calc(836px + 2 * 16px);
`;

export default LayoutWidthContainer;
