import React from "react";

import { useConfig } from "gatsby-theme-advanced";

// import { AdvancedLogo } from "../../icons";

import * as S from "./style";
import { StaticImage } from "gatsby-plugin-image";
import { _getStaticImage } from "gatsby-plugin-image/dist/src/components/static-image.server";


const Navigation = (): JSX.Element => {
  const config = useConfig();


  return (
    <S.Wrapper>
      <S.HomeButton to="/">
        <StaticImage src="../../icons/logo-1.jpeg" alt="logo" width={36} height={36} imgStyle={{ width: "36px",    height: "36px",    borderRadius: "50%", }} />
        {/* <AdvancedLogo width={36} height={36} /> */}
        <S.SiteTitle>{config.website.titleShort}</S.SiteTitle>
      </S.HomeButton>
      <S.NavGrid>
        <S.NavButton to="/">Bài viết</S.NavButton>
        <S.NavButton noBasePath to="/about">
          Về tôi
        </S.NavButton>
      </S.NavGrid>
    </S.Wrapper>
  );
};

export default Navigation;
