import React from "react";
import styled from "styled-components";

import { Disqus, Types } from "gatsby-theme-advanced";

import Intro from "./Intro";
import Render from "./Render";
import Share from "./Share";
import BlockQuoteBig from "./BlockQuoteBig";
import PageWithComments from "../CommentBox/PageWithComments";

const Wrapper = styled.main`
  width: 100%;

  display: grid;
  grid-gap: 24px;
  justify-items: center;
`;

const WrapperDisqus = styled.main`
  width: 100%;
  max-width: 836px;
  display: inline-block;  
  background-color: #ececec;
  padding: 10px;
`;

type ArticleProps = {
  post: Types.Post;
};

const WrapperPadding = styled.div`
  padding-top: 32px;
`;

const Article = ({ post }: ArticleProps): JSX.Element => (
  <div>
    
  <Wrapper>
    <Intro post={post} />
    <BlockQuoteBig post={post} />    
    <Render post={post} />
    <WrapperPadding>
      <Share post={post} />    
    </WrapperPadding>
    <WrapperDisqus>
      {/* <Disqus post={post} /> */}
      <PageWithComments />
    </WrapperDisqus>
  </Wrapper>
  
  
  
  </div>
);

export default Article;
